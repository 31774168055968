import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { LeadScripts } from 'services/lead-scripts';
import { Leads } from 'services/leads';
import { c } from 'common/common';

export class LeadScript {
    static inject = [EventAggregator, LeadScripts, Leads];
    _ea;
    _leadScripts;
    _leads;

    @bindable leadId;
    @bindable scriptId;
    @bindable type;
    @bindable person;
    @bindable minimal = false;

    script;
    scriptIdsInOrder = [];

    _handlers = [];

    constructor(ea, leadScripts, leads) {
        this._ea = ea;
        this._leadScripts = leadScripts;
        this._leads = leads;
    }

    attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.changeScriptType, (data) => {
            this.type = data.scriptType;
        }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.changeScriptPerson, (data) => {
            this.person = data.scriptPerson;
        }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    leadIdChanged() {
        if (!this.leadId) return;
        this._loadScriptForLead();
    }

    typeChanged() {
        if (!this.type) return;
        this._loadScriptForLead();
    }

    personChanged() {
        if (!this.person) return;
        this._loadScriptForLead();
    }

    async _loadScriptForLead() {
        if (!this.leadId || !this.type || !this.person) return;
        try {
            this.script = await this._leadScripts.scriptForLead(this.leadId, this.type, this.person);
            this._initialize();
        } catch (err) {
            console.log(err);
        }     
    }

    async scriptIdChanged() {
        if (!this.scriptId) return;
        try {
            this.script = await this._leadScripts.sampleScript(this.scriptId);
            this._initialize();
        } catch (err) {
            console.log(err);
        }     
    }

    _initialize() {
        if (!this.script) return;
        this.currentScript = this.script.find(x => x.type === 'start');
        if (!this.currentScript) return;
        this.scriptIdsInOrder.push(this.currentScript.id);
        this._loadLead();
    }

    async _loadLead() {
        if (!this.leadId) {
            // Loading by script with sample data
            this.lead = { fullName: '', email: '' };
            return;
        }
        try {
            this.lead = await this._leads.byId(this.leadId);
        } catch (err) {
            console.log(err);
        }
    }

    optionClicked(option) {
        if (option.toScriptId) {
            this.currentScript = this.script.find(x => x.id === option.toScriptId);
            if (!this.currentScript) return;
            this.scriptIdsInOrder.push(this.currentScript.id);
        }
    }

    goBack() {
        try {
            if (!this.scriptIdsInOrder.length) return;
            this.scriptIdsInOrder.pop();
            const goToId = this.scriptIdsInOrder[this.scriptIdsInOrder.length - 1];
            this.currentScript = this.script.find(x => x.id === goToId);
        } catch (err) {
            console.log(err);
        }
    }
}